import React from "react"
import { Flex, Grid } from "@theme-ui/components"
import BannerThumb from "../bannerThumb"

const BannerListVertical = ({ banners, channel }) => {
  return (
    <Grid columns={[1]}>
      {banners.length > 0 &&
        banners.map(banner => (
          <Flex sx={{ img: { width: "100%" }, justifyContent: "center" }}>
            <BannerThumb banner={banner} channel={channel} />
          </Flex>
        ))}
    </Grid>
  )
}

export default BannerListVertical
