import React, { useState } from "react"
import { Box, Text, Heading, Grid } from "@theme-ui/components"
import { getVideoPath } from "../utils/path"
import { GatsbyImage } from "gatsby-plugin-image"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import { InboundLink } from "./link"
import { Image } from "react-datocms"
import { PlayCircle } from "react-feather"

const VideoThumb = ({
  search = false,
  video,
  category,
  side,
  theme = undefined,
  locale,
  playlistId,
}) => {
  const light = getColor(themeUiTheme, "light")
  const [showHover, SetShowHover] = useState(false)
  const firstPublishedAt =
    video.firstPublishedAt ||
    video._firstPublishedAt ||
    video.meta.firstPublishedAt

  const toHHMMSS = duration => {
    if (duration == null) {
      return "00:00"
    }

    const sec_num = parseInt(duration, 10)
    const hours = Math.floor(sec_num / 3600)
    const minutes = Math.floor((sec_num % 3600) / 60)
    const seconds = sec_num % 60

    return hours > 0
      ? `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
          2,
          "0"
        )}:${String(seconds).padStart(2, "0")}`
      : `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
          2,
          "0"
        )}`
  }

  return (
    <InboundLink
      className="video-thumb-link"
      sx={{ color: "dark", textDecoration: "none" }}
      state={{ category }}
      to={getVideoPath(video, locale || video.locale, playlistId)}
      onMouseOver={() => {
        SetShowHover(true)
      }}
      onMouseLeave={() => {
        SetShowHover(false)
      }}
    >
      <Grid
        sx={{
          gridTemplateColumns: side && ["auto minmax(150px,1fr)"],
        }}
        gap={side ? [3] : [1]}
      >
        <Box
          sx={{
            backgroundColor: "lightBackground",
            overflow: "hidden",
            width: "100%",
            position: "relative",
            zIndex: 0,
            minWidth: side && ["167px", "167px", "167px", "217px"],
            maxWidth: side && ["167px", "167px", "167px", "217px"],
            maxHeight: side && ["94px", "94px", "94px", "122px"],
            minHeight: side && ["94px", "94px", "94px", "122px"],

            ".gatsby-image-wrapper": {
              position: "relative",
              width: "100%",
              img: {
                transition: "all .2s ease-in-out",
                objectFit: "contain!important",
                width: "100%",
                height: "auto",
              },
            },
            "&:after": {
              content: "''",
              width: "100%",
              height: "100%",
              position: "absolute",
              left: 0,
              top: 0,
              backgroundColor: theme
                ? theme
                : video.category.channel.pageLink.slug,
              opacity: showHover ? 0.8 : 0,
              transition: "all .2s ease-in-out",
            },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              right: [2],
              bottom: [2],
              backgroundColor: showHover
                ? "light"
                : theme
                ? theme
                : video.category.channel.pageLink.slug,
              px: [1],
              py: [1],
              color: showHover
                ? theme
                  ? theme
                  : video.category.channel.pageLink.slug
                : "light",
              fontSize: ["12px"],
              fontWeight: "500",
              zIndex: 1,
              transition: "all .2s ease-in-out .05s",
            }}
          >
            <Text>{toHHMMSS(video.duration)}</Text>
          </Box>

          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              zIndex: 99,
              transform: "translate(-50%,-50%)",
              opacity: showHover ? 1 : 0,
              transition: "all .2s ease-in-out .05s",
            }}
          >
            <PlayCircle size={42} color={light} />
          </Box>

          {!search && video.thumbnail && video.thumbnail.desktop && (
            <GatsbyImage image={video.thumbnail.desktop} alt="" />
          )}

          {video.thumbnail && video.thumbnail.responsiveImage && (
            <Box
              sx={{
                img: {
                  transform: showHover ? "scale(1.05)" : "scale(1)",
                  transition:
                    "transform .2s ease-in-out, opacity .5s ease 0s !important",
                },
              }}
            >
              <Image data={video.thumbnail.responsiveImage} />
            </Box>
          )}
        </Box>
        <Box>
          <Text
            sx={{
              fontSize: [0],
              letterSpacing: "0.09",
              lineHeight: "1",
            }}
          >
            {new Date(firstPublishedAt)
              .toLocaleDateString()
              .replaceAll("/", ".")}
          </Text>
          <Heading
            as="h2"
            variant="h3"
            sx={{
              fontSize: side && [2, 2],
              my: [2],
              color: "dark",
              display: "-webkit-box",
              "-webkit-line-clamp": side ? ["2", "2", "2", "2"] : ["3"],
              "-webkit-boxOrient": "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {video.title}
          </Heading>
          <Box
            sx={{
              my: [2],
              width: "99%",
              display: "-webkit-box",
              "-webkit-line-clamp": "1",
              "-webkit-boxOrient": "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {video.speaker &&
              video.speaker.map((speaker, index) => (
                <>
                  <Text variant="speaker">{speaker.name}</Text>
                  {video.speaker.length > 1 &&
                    index !== video.speaker.length + 1 &&
                    ","}
                </>
              ))}
            {video.speakers &&
              video.speakers.map((speaker, index) => (
                <>
                  <Text variant="speaker">{speaker.name}</Text>
                  {video.speakers.length > 1 &&
                    index !== video.speakers.length + 1 &&
                    ", "}
                </>
              ))}
          </Box>
          {!side && (
            <Box
              variant="links.badge.smallCategory"
              sx={{
                backgroundColor: theme
                  ? theme
                  : video.category.channel.pageLink.slug,
              }}
            >
              {video.category.title}
            </Box>
          )}
        </Box>
      </Grid>
    </InboundLink>
  )
}

export default VideoThumb
