import React, { useEffect, useRef } from "react"
import { Box, Image } from "@theme-ui/components"
import { GatsbyImage } from "gatsby-plugin-image"
import { OutboundLink } from "./link"
import UseIntersection from "../utils/useIntersection"
import { track } from "@amplitude/analytics-browser"

const BannerThumb = ({ banner, channel, small }) => {
  const firstUpdate = useRef(true)
  const bannerRef = useRef()

  const inViewport = UseIntersection(bannerRef, "-150px")
  const isBrowser = typeof window !== "undefined"

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false
      return
    }
    track("Viewed Banner", {
      banner_name: banner.title,
      banner_url: banner.url,
    })
    if (typeof window !== "undefined" && window.dataLayer !== undefined) {
      window.dataLayer.push({
        event: "viewed_banner",
        banner_name: banner.title,
        banner_url: banner.url,
        banner_channel: channel,
        pageURL: isBrowser && window.location.href,
      })
    }
  }, [inViewport])

  return (
    <Box
      ref={bannerRef}
      sx={{
        textAlign: "center",
        overflow: "hidden",
        height: "100%",
      }}
      onClick={() => {
        track("Clicked Banner", {
          banner_name: banner.title,
          banner_url: banner.url,
        })
        if (typeof window !== "undefined" && window.dataLayer !== undefined) {
          window.dataLayer.push({
            event: "clicked_banner",
            banner_name: banner.title,
            banner_url: banner.url,
            banner_channel: channel,
            pageURL: isBrowser && window.location.href,
          })
        }
      }}
    >
      <OutboundLink
        to={banner.url}
        sx={{
          textDecoration: "none",
          display: "inline-block",
          width: "100%",
          height: "100%",
        }}
      >
        {banner.image && (
          <Box
            sx={{
              overflow: "hidden",
              img: {
                width: "100%",
                height: "100%",
                maxWidth: !small ? "336px" : "300px",
                maxHeight: !small ? "280px" : "250px",
                objectFit: "cover",
              },
              ".gatsby-image-wrapper": {
                height: "100%",
                width: "100%",
                maxWidth: !small ? "336px" : "300px",
                maxHeight: !small ? "280px" : "250px",
              },
            }}
          >
            {banner.image.gatsbyImageData ? (
              <GatsbyImage image={banner.image.gatsbyImageData} alt="" />
            ) : (
              banner.image.url && <Image src={banner.image.url} />
            )}
          </Box>
        )}
      </OutboundLink>
    </Box>
  )
}

export default BannerThumb
