import React from "react"
import { Flex, Heading } from "@theme-ui/components"
import { Play } from "react-feather"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"

const PlaylistHeading = ({ channelSlug, title }) => {
  return (
    <Flex
      sx={{
        alignItems: "center",
        borderTop: "1px solid",
        borderColor: "dark",
        pt: [2],
      }}
    >
      <Play
        size={20}
        color={getColor(themeUiTheme, channelSlug)}
        style={{ marginRight: 8 }}
      />
      <Heading
        as="h2"
        variant="h2"
        sx={{
          p: [0],
          m: [0],
        }}
      >
        {title}
      </Heading>
    </Flex>
  )
}

export default PlaylistHeading
